import React from "react";
import { useState } from "react";
import { Camera, Mic } from "react-bootstrap-icons";

import "../index.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Carousel from "react-bootstrap/Carousel";

import { StickyContainer, Sticky } from "react-sticky";
import ProjectHeader from "../page-elements/ProjectHeader";
import YoutubePlayer from "../page-elements/YoutubePlayer";
import ImageCarousel from "../page-elements/ImageCarousel";
import NextProject from "../page-elements/NextProject";

function Foto(props) {
    var slug = "foto";

    var [rMore1, setRMore1] = useState(false);
    var [rMore2, setRMore2] = useState(false);
    var [rMore3, setRMore3] = useState(false);

    var desc =
        "Tempo is a novel form of digital map, taking away the need for annoying speech-based commands - 'Turn Right in 300 yds'. This works through spatialising the user's playlist, allowing them to intuitively follow their music to the destination.";

    var collaborators = {};

    var skills = {
        "Audio Engineering": "#",
        "OSC": "#",
        "Human Centred Design": "#",
        "UI/UX": "#",
        "Optimisation": "#",
        "Data Analysis": "#",
        "User Testing":"#"
    };

    const header_image = `./media/${slug}/header.jpg`;


    // style={{ maxWidth: "60%", minWidth: "200px", marginRight: "10px" }}

    return (
        <div>
            {props.topbar ? <div className="topbar-spacer"></div> : null}
            <ProjectHeader
                title="Tempo"
                slug={slug}
                desc={desc}
                collaborators={collaborators}
                completed="Jan - Jun 2024, Masters Project"
                skills={skills}
                solo
            />

            <Row className="project-row" id="hcd">
                <Col xs={12} md={6} className="project-col">
                    <h1 className="mta">'Problems facing existing digital maps...'</h1>
                    <div className="mb10 jt">
                        Digital mapping applications such as Google Maps have revolutionised the way in which we navigate the modern world. However, 
                        most digital maps require the user to look at their phone screen for the directional commands - leading to a potentially dangerous
                        visual distraction. To combat this, many apps have introduced speech-based navigation as an altertanitve. However, most users find
                        the commands 'annoying' and tend to disable this feature...
                    </div>
                    <div className="mba">
                        {rMore1 ? (
                            <div className="read-more-text mba">
                                <div className="mb10 jt">
                                    Many users find the speech-based commands such as "Turn Left in 300 Yds" frustrating as they suddenly interrupt their music and
                                    often leave them feeling as though they are being 'told what to do'. I wanted to develop a more seamless non-speech form of auditory
                                    navigation in an attempt to create a more usable feature. 
                                </div>
                            </div>
                        ) : (
                            <a
                                href="#hcd"
                                className="read-more"
                                onClick={() => {
                                    setRMore1(true);
                                }}
                            >
                                Read more...
                            </a>
                        )}
                    </div>
                </Col>
                <Col xs={12} md={6} className="project-col" style={{ textAlign: "center" }}>
                    <Image className="project-image3" fluid src={`./media/${slug}/tempo_map.jpg`} alt=" " />
                </Col>
            </Row>
            
            <Row className="project-row">
                <Col xs={12} md={6} className="project-col" style={{ textAlign: "center" }}>
                    <Image fluid src={`./media/${slug}/tempo_diagram.jpg`} alt=" " />
                </Col>
                <Col xs={{ span: 12, order: "first" }} md={{ span: 6, order: "last" }} className="project-col">
                    <h1 className="mta">The Solution: Tempo</h1>
                    <div className="mb10 jt">
                        After extensieve concept exploration, I designed an alternative feature - Tempo. Tempo spatialises the user's music to give them the impression of
                        the music command from a certain direction. When linked to a pre-defined route, this creates a far more pleasurable navigtional interaction as it removes
                        the need for disruptive and annoying speech-based commands. Instead, the user can simply listen to their music as they would normally, and follow it to their
                        destination.
                    </div>
                    <div className="mba jt">- diagram of Tempo vs an existing version.</div>
                </Col>
            </Row>
            
            <Row className="project-row">
                <h1 className="" id="develop">
                    Development Srategy
                </h1>
                <div className="mb30 jt">
                    Due to the User Experience of Tempo being a central focus of the project, I devised a feedback-driven approach inlcluding: an optimisation study to ensure
                    high intuition of the technology, and a contextual enquiry to identify any final improvements prior to benchmarking Tempo against Google Maps. All studies 
                    were carried out on a diverse range of participants to best represent the general public, consent was given to use their data.
                </div>
                <Image fluid src={`./media/${slug}/tempo_strategy.jpg`} alt=" " />
            </Row>

            <Row className="project-row" id="">
                <Col xs={12} md={6} className="project-col">
                    <h1 className="mta">Study 1: Optimisation Study</h1>
                    <div className="mba jt">
                        The first study featured an exam-like format, a testing software was developed to randomly move the percieved spatial position of a song
                        around a participants head. Once the motion of the song had finished, the participant was asked to turn and face the music. Once satisfied,
                        the participant's orientation was recorded. This data indicated the accuracy of the spatial positioning of the music, as well as the time taken for the
                        participant to localise the sound source.
                    </div>
                </Col>
                <Col xs={12} md={6} className="project-col">
                    <Image className="project-image3" fluid src={`./media/${slug}/tempo_study1.jpg`} alt=" " />
                </Col>
            </Row>

            <Row className="project-row">
                <Col xs={12} md={6} className="project-col" style={{ textAlign: "center" }}>
                    <ImageCarousel slug={slug} numImages={3} gallerySlug="gallery1" />
                </Col>
                <Col xs={{ span: 12, order: "first" }} md={{ span: 6, order: "last" }} className="project-col">
                    <h1 className="mta">Optimisation Study: Command Iterations</h1>
                    <div className="mb10 jt">
                        The optimisation study adopted an iterative and agile approach. This meant that the 24 participants were divided into 3 groups of 8
                        this allowed me to test different versions of the sound's movement to identify the most intuitive version. To decide this, 3 iterations were made: an instant change of direction, a gradual panning 
                        of the sound, and a gradual pan of the sound alongside a subtle volume increase. 
                    </div>
                    <div className="mba jt">- different iterations tested during the optimisation study.</div>
                </Col>
            </Row>

            <Row className="project-row">
                <Col xs={12} md={6} className="project-col">
                    <h1 className="mta">Optimisation Study: Results</h1>
                    <div className="mb10 jt">
                        Based upon the recorded metrics, statistical analysis and visualisation plots were created to determine the optimal iteration. This concluded that there was
                        a significant difference in the time taken to localise the sound source. However, there was no difference in the localisation accuracy, with every iteration scoring an 
                        accuracy of above 93%. This confirmed that the concept of using spatially manipulated audio streams was indeed an intuitive form of directional command.

                    </div>
                    <div className="mba jt" style={{ textAlign: "right"}}>Result plots of the optimiation study -</div>
                </Col>
                <Col xs={12} md={6} className="project-col" style={{ textAlign: "center" }}>
                    <ImageCarousel slug={slug} numImages={2} gallerySlug="gallery2" />
                </Col>
            </Row>

            <Row className="project-row">
                <Col xs={12} md={6} className="project-col" style={{ textAlign: "center" }}>
                    <Image fluid src={`./media/${slug}/tempo_contextual.jpg`} alt=" " />
                </Col>
                <Col xs={{ span: 12, order: "first" }} md={{ span: 6, order: "last" }} className="project-col">
                    <h1 className="mta">Study 2: Contextual Enquiry</h1>
                    <div className="mb10 jt">
                    Now that the concept's effectiveness had been proved, it was essential to pilot the technology prior to testing the final version against Google Maps. 
                    To do this I recruited 3 participants - including an inudstry-facing audio engineer, to try out Tempo when navigating a pre-defined route. Interviews were then
                    conducted to observe any shared thoughts and identify areas for improvement.
                    </div>
                    <div className="mba jt">- diagram of the route taken during the enquiry.</div>
                </Col>
            </Row>

            <Row className="project-row">
                <Col xs={12} md={6} className="project-col">
                    <h1 className="mta">Contextual Enquiry: Final Improvements</h1>
                    <div className="mb10 jt">
                        After collating insights from the interviews, two key changes were made to Tempo. The first was the implementation of the cross-fader, this meant that
                        there was never an absence of music during the use of Tempo, thus ensuring that directional commands would not be missed. Secondly, a pre-alerting feature was 
                        added, this aimed to subtly warn the user of a direction prior to it occuring. This also aimed to reduce the number of errors encountered when using Tempo.
                    </div>
                    <div className="mba jt" style={{ textAlign: "right"}}>Crossfader + Pre-alert improvements -</div>
                </Col>
                <Col xs={12} md={6} className="project-col" style={{ textAlign: "center" }}>
                    <Image fluid src={`./media/${slug}/tempo_contresults.jpg`} alt=" " />
                </Col>
            </Row>

            <Row className="project-row">
                <h1 className="" id="develop">
                    Tempo Software Diagram
                </h1>
                <div className="mb30 jt">
                    Once both studies had been completed, I was ready to develop a works-like prototype of Tempo for extensive testing. The prototype featured a Supperware Headtracker that communicated
                    to a MaxMSP script running on my laptop. Together, live spatial audio rendering was achieved, meaning that a sound could be moved relative to the user's head
                    and provide specifc directional commands when needed. The controller component was also added as an external input to simulate the route-planning and GPS features during the full
                    benchmarking test against Google Maps. For more technical information on the system, please check out the
                        {" "}<a href="https://drive.google.com/file/d/1VdHbwrBs1tSfvODSYGBG9GEraT8YlzCF/view?usp=sharing" target="_blank">
                            supplementary academic report
                        </a>{""} about this project.
                </div>
                <Image fluid src={`./media/${slug}/tempo_system.jpg`} alt=" " />
            </Row>

            <Row className="project-row">
                <h1 className="" id="develop">
                    Final Benchmarking vs Google Maps
                </h1>
                <div className="mb30 jt">
                    The final stage of this project was evaluating the experience of Tempo against an exisitng speech-based form of navigation, I chose to compare Tempo against Google Maps - the most 
                    widely used digital map on the market. To do this I gathered 2 groups of 8 participants, Group A was assigned one route with Google Maps and one route with Tempo - the routes were inverted for Group B.
                    The routes were unique but equi-distant and consisted of an equal number of directional commands. From the results, I monitored the time taken to complete the route and number of errors commited. Furthermore, follow-up
                    semi-structured interviews were conducted in order to gain insights into how the user-experience of both technologies compared.  
                </div>
                <Image fluid src={`./media/${slug}/tempo_usertests.jpg`} alt=" " />
            </Row>

            <Row className="project-row">
                <h1 className="" id="develop">
                    Enhanced User-Experience
                </h1>
                <div className="mb30 jt">
                    When observing the results from the final benchmarking study, I discovered that approximately 92% of the participants preffered the more subtle and muscial interaction of Tempo over the 
                    existing speech-based style of Google Maps. Furthermore, no significant difference in the time taken and number of errors identified was found between the two maps - suggesting
                    that Tempo matches the effectiveness of speech-based maps as a form of auditory navigation. With the introduction of head-trackers into modern headphones, I truly believe that an audio-based
                    navigational innovation like Tempo would greatly enhance the user-experience of digital maps.
                </div>
                <Image fluid src={`./media/${slug}/tempo_final.jpg`} alt=" " />
            </Row>

            <Row className="project-row">
                <h1>Supplementary Academic Paper</h1>
                <div className="mb10 jt">
                        I wanted to keep my website simple, but for more information about each process check out the 
                        {" "}<a href="https://drive.google.com/file/d/1VdHbwrBs1tSfvODSYGBG9GEraT8YlzCF/view?usp=sharing" target="_blank">
                            academic report
                        </a>{""} I wrote that summarises the research, development and testing of Tempo!
                    </div>
            </Row>

            <NextProject name="projects" url="/" />
        </div>
    );
}

export default Foto;
